import { createTestingPinia } from '@pinia/testing';
import { shallowMount } from '@vue/test-utils';
import { createHead } from '@vueuse/head';
import { setActivePinia } from 'pinia';

import Component from './app.vue';

const head = createHead();

let wrapper;

describe('App', () => {
  beforeEach(() => {
    setActivePinia(
      createTestingPinia({
        initialState: {
          app: {
            ready: true,
          },
        },
      }),
    );
    wrapper = shallowMount(Component, {
      global: {
        plugins: [head],
      },
    });
  });

  test('render', () => {
    expect(wrapper.isVisible()).toBe(true);
  });
});
